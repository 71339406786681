import React from 'react'
import { graphql } from 'gatsby'
//component
import Seo from '../components/SEO'
import ProductList from '../components/product/ProductList'
import ProductListSelect from '../components/product/ProductListSelect'

const TemplateProductListColor = ({ data, location, pageContext }) => {
  return (
    <>
      <Seo title={pageContext.title} slug={location.pathname} />
      <ProductList data={data.c.nodes} location={location} />
      <ProductListSelect
        select={data.menu.nodes}
        location={location}
        switchText={data.switchText.switchProductType.switchProductType}
      />
    </>
  )
}

export const query = graphql`
  query ($product: String, $color: String) {
    c: allContentfulProduits(
      filter: {
        productType: { slug: { eq: $product } }
        color: { slug: { eq: $color } }
      }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        title
        slug
        productType {
          slug
        }
        subtitle
        img {
          title
          gatsbyImageData(
            width: 400
            height: 400
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
    menu: allContentfulProductType(sort: { fields: order, order: ASC }) {
      nodes {
        colorVar {
          title
          slug
        }
        title
        slug
      }
    }
    switchText: contentfulConfig {
      switchProductType {
        switchProductType
      }
    }
  }
`

export default TemplateProductListColor
